<template>
    <div>
        <div>
            <div class="content-header container-fluid bg-white">
                <div class="row">
                    <div class="col-sm-4">
                        <h4><i class="ti-files"> </i> 档案管理 | 操作日志</h4>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="w-block" style="padding: 2px;">
                            <el-select v-model="grid.sea.TID" @change="filter" clearable placeholder="请选择老师">
                                <el-option v-for="item in teacherList" :key="item.Id" :value="item.Id"
                                    :label="item.Name" />
                            </el-select>
                            <el-date-picker v-model="grid.sea.SDATE" value-format="yyyy-MM-dd" @change="filter" type="date" placeholder="选择开始日期">
                            </el-date-picker>
                            <el-date-picker v-model="grid.sea.EDATE" value-format="yyyy-MM-dd" @change="filter" type="date" placeholder="选择结束日期">
                            </el-date-picker>
                            <!-- <el-select v-model="grid.sea.TYPE" @change="filter" clearable placeholder="请选择类型">
                                <el-option label="目录" :value="1" />
                                <el-option label="文件" :value="2" />
                            </el-select> -->
                        </div>
                        <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total"
                            :page-index="grid.sea.PAGE_INDEX+1" :page-size="grid.sea.PAGE_SIZE"
                            @page-changed="loadPage">
                            <el-table-column prop="ContentName" label="名称" width="200" />
                            <el-table-column prop="Path" label="路径" width="300">
                                <template #default="scope">
                                    <span style="color: #12b1b7;">{{ scope.row.ArchiveName }}</span>/{{ scope.row.Path }}
                                   
                                </template>
                            </el-table-column>
                            <el-table-column label="类型" width="100" align="center">
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.ContentType==1" type="success">目录</el-tag>
                                    <el-tag v-else type="warning">文件</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="OperateType" label="方式" width="150" align="center">
                                <template slot-scope="scope">
                                    <el-tooltip v-if="scope.row.OperateType==20" class="item" effect="dark"
                                        content="加入回收站" placement="right">
                                        <el-link type="warning"
                                            :underline="false">{{scope.row.OperateTypeText}}</el-link>
                                    </el-tooltip>
                                    <el-tooltip v-else-if="scope.row.OperateType==22" class="item" effect="dark"
                                        content="彻底删除" placement="right">
                                        <el-link type="danger"
                                            :underline="false">{{scope.row.OperateTypeText}}</el-link>
                                    </el-tooltip>
                                    <span v-else>{{scope.row.OperateTypeText}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="OperateContent" label="内容" width="200" />
                            <el-table-column prop="TeacherName" label="操作人" width="100" align="center" />
                            <el-table-column prop="OperateTime" label="操作日期" width="150" align="center" />
                            <el-table-column />
                        </wgrid>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

    import Wgrid from "@/components/wgrid";
    export default {
        name: "index",
        components: { Wgrid },
        data() {
            return {
                grid: {
                    sea: {
                        PAGE_SIZE: 12,
                        PAGE_INDEX: 0,
                    },
                    ls: [],
                    total: 0,
                    loading: false
                },
                teacherList: []
            }
        },
        created() {
            this.getTeachers();
            this.getList();
        },
        methods: {
            filter() {
                this.grid.sea.PAGE_INDEX = 0;
                this.grid.ls = [];
                this.getList();
            },
            loadPage(pi) {
                this.grid.sea.PAGE_INDEX = pi - 1;
                this.getList();
            },
            getList() {
                let self = this;
                this.grid.loading = true;
                this.whale.remote.getCollection({
                    url: "/api/School/DOC/ContentApi/GetOperateLogs",
                    data: this.grid.sea,
                    completed: function (its, n) {
                        self.grid.ls = its;
                        self.grid.total = n;
                        self.grid.loading = false;
                    }
                })
            },
            getTeachers() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/DOC/PowerApi/GetTeachers",
                    data: { isPT: false },
                    completed: function (its) {
                        console.log(its.DATA)
                        self.teacherList = its.DATA;
                    }
                })
            },
        }
    }
</script>